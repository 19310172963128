<template>
    <form id="frm_action">
      <div class="p-formgrid">
          <div class="p-grid">
              <!-- <div class="p-field p-col-12">
                  <label class="p-col-fixed txt-left">Tên <span class="span-cus">*</span></label>
                  <div class="p-col">
                      <InputText v-model="name" type="text" id="name" placeholder="Tên"/>
                  </div>
              </div> -->
              <div class="p-field p-col-12">
                <!-- <label class="p-col-fixed txt-left">Nội dung <span class="span-cus">*</span></label> -->
                <input class="input_tmp_validator" id="validator_content">
                <div class="p-col">
                  <CkditorCore :contentSet="content" @newdata="handleDataCkditor($event,'content')">{{content}}</CkditorCore>
                </div>
              </div>
          </div>
      </div>
    </form>
</template>

<script>
// import DataServices from '@/core/DataServices'
// import ApiRepository from "@/core/ApiRepository";
import CkditorCore from '../CkditorCore';
export default {
  components: {
    CkditorCore
  },
  props: {
    mode: String,
    model: Object,
    users: Array,
    triggerSave: Boolean
  },
  data() {
    return {
      formMode: this.mode,
      trigger_save: this.triggerSave,
      name: '',
      ordinal: 0,
      images: [],
      models: {},
      deleteId: null,
      deleteName: null,
      showWarningDialog: false,
      pagination: true, // true cho phép phân trang
      paginationRows: 10, // số dòng trên 1 trang,
      listData: [],
      // dataModel: null,
      componentKey: 0,
      filters: {},
      maxFileSize: 2097152 , // 2MB   /*104857600, // 100MB*/
      content: this.model.content
    }
  },
  watch: {
    'triggerSave'() {
        if (this.triggerSave) {
          this.saveData();
      }
    },
  },
  async mounted () {
    // await this.getData();
    this.content = this.model.content;
    // this.name = this.model.name;
  },
  async created() {
  },
  methods: {
    getField(col) {
      return col.key;
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getHeaderStyleView() {
      var style = "display : none";
      return style;
    },
    getDataRows(col, row) {
      var value = '';
      if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
        var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
        value = result;
      }
      return value
    },
    clickEdit() {
      this.$router.push({ path: '/admin/list-policy'});
    },
    backToList() {
      this.$router.push({ path: '/admin/list-policy' });
    },
    clickDelete() {
      this.deleteId = this.models.id
      this.deleteName = this.models.fullname
      this.showDeleteDialog = true
    },
    async validateData() {
        this.validationErrors = {};
        
        // let name_add = document.getElementById("name");
        // if (this.name == undefined || this.name == null || this.name == ''){
        //     name_add.setCustomValidity("Tên không được để trống!");
        // }else {
        //     name_add.setCustomValidity("");
        // }
        
        let content_add = document.getElementById("validator_content");
        if (this.content == undefined || this.content == null || this.content == ''){
            content_add.setCustomValidity("Mô tả không được để trống!");
        }else {
            content_add.setCustomValidity("");
        }

        var inpObj = document.getElementById("frm_action");
        if (!inpObj.checkValidity()){
            console.log('test=====================');
            inpObj.reportValidity();
            return true;
        }
    },
    handleDataCkditor: function(e,key) {
      var dataContent = (e.length) ? e[0]:'';
      if (key == 'content'){
        this.content = dataContent;
      }
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      this.$emit('update:triggerSave', false);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      
      if(Object.entries(this.validationErrors).length === 0){
        this.$commonFuction.is_loading(true);
        this.showWarningDialog = false;
        var params = {
            // name: this.name,
            content: this.content
        }
        this.$emit('save', params);
      }else{
        this.$commonFuction.is_loading(false);
      }
    },
    // async getData() {
    //   var {data} = await this.$apollo.query({
    //     query: DataServices.getList('policy', {"fields" : "id name content"}),
    //     variables: {
    //       where_key: {
    //         deleted: {_eq: false}
    //       }
    //     },
    //     fetchPolicy: "network-only"
    //   })
    //   var model = data[Object.keys(data)];
    //   if (model.length > 0) {
    //     this.models = model[0];
    //     this.name = this.models.name;
    //     this.content = this.models.content;
    //     this.componentKey++;
    //   }
    // }
  },
  apollo: {
  },
  
}
</script>
<style lang="scss">
.p-inputnumber-buttons-stacked {
  .p-inputnumber-button-group {
    .p-button.p-button-icon-only { padding: 0 !important; }
  }
}
.view-action{
  .toolbar-top{background: #fff; padding: 0; border: 0 none;margin-bottom: 16px; padding-bottom: 10px; border-bottom: 1px solid #EEEEEE;
    .manager-title{border: 0 none; padding: 0; margin: 0;}
    .p-toolbar-group-right {
      .p-button{margin-right: 8px; width: auto;
        &:last-child{margin-right: 0;}
      }
    }
  }
  .p-tabview{
    .p-tabview-panels{ padding: 0;}
    .p-tabview-nav{
      li{margin-right: 8px;
        .p-tabview-nav-link{border: 1px solid #B4B4B4; border-radius: 5px 5px 0px 0px !important; background: #eee; font-size: 15px;
          &:hover{text-decoration: none;}
        }
        &.p-highlight{
          .p-tabview-nav-link{background: #00A8FF !important;border: 1px solid #B4B4B4; border-radius: 5px;}
        }
        &:hover{
          .p-tabview-nav-link{background: #00A8FF !important; color: #fff !important;}
        }
      }
    }
  }
  .p-formgrid { margin: 0;
    .p-panel-header {color: #000000; font-size: 18px; font-weight: bold; background: #EEEEEE;}
    .p-card-field{ padding: 24px;
      .p-field{
        .p-col-fixed { width: 300px; display: inline-block !important; margin: 0;}
        .p-inputtext p-component p-filled{width:500px !important; border-radius: 5px;
          &.disabled{background: #EEEEEE; border: 0 none; padding: 8px 10px; color: #707070; height: 40px;}
          &.rows-4{height: 80px;}
          .file-select{margin: 0;
            input[type="file"]{display: none;}
            .select-button{display: inline-block; cursor: pointer; background: transparent; border-radius: 5px;  color: #000000; font-size: 13px; padding: 3px 16px;  border: 1px solid #B4B4B4;}
          }
        }
        .box-images{ margin-top: 10px; position: relative;
          .item-img{height: 150px;  margin-right: 8px; margin-bottom: 8px; display: inline-flex; position: relative; border: 0.5px solid #ccc;
            &.p-col-5{width: 18%;;}
            img{max-width: 100%; height: auto;margin: 0 auto; object-fit: contain;}
            .button-clear{width: 18px; height: 18px; background: #F5564E; float: right; cursor: pointer; position: absolute; right: 0px;
              img{vertical-align: top;}
            }
          }
        }
      }
    }
  }
  .p-rates{
    .top-rates{ padding: 16px;color: #000;border-bottom: 1px solid #B4B4B4; 
      label{margin: 0;}
      span{font-weight: 600; padding-left: 100px;}
    }
    .list-rates{padding: 16px;}
  }
  .table-view >.p-datatable .p-datatable-tbody > tr > td {
    color: #707070 !important;
  }
  .p-inputtext {
    min-height: 40px;
  }
  .p-formgrid .p-card-field .p-field .p-col-width {
    min-height: 40px;
    width: 100% !important;
  }
  .vs__dropdown-option.vs__dropdown-option--selected {
    background-color: #142053 !important;
    color: #ffffff;
  }
}

.span-cus{
  color : red !important;
}

label.text-active {
  margin: 0 1rem;
}
</style>